.BookManager_main_content {
  height: 80vh;
  overflow-y: auto;
  margin-top: 30px;
  padding: 10px 45px 0 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.BookManager_wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.BookManager_wrap_label,
.BookManager_wrap_content {
  background-color: var(--white-obj);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 11px 12px 10px;

  font-weight: 300;

  strong {
    font-weight: 500;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }
  ol {
    list-style: decimal;
    padding-left: 20px;
  }
}
