.ImportantInformation_main_content {
  max-height: 80vh;
  min-height: 100px;
  overflow-y: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px 45px 3px 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-weight: 300;

  strong {
    font-weight: 500;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }
  ol {
    list-style: decimal;
    padding-left: 20px;
  }
}

.ImportantInformation_wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ImportantInformation_wrap_label,
.ImportantInformation_wrap_content {
  background-color: var(--white-obj);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 11px 12px 10px;
}
.ImportantInformation_wrap_content a {
  color: var(--blue);
  text-decoration: underline;
}
