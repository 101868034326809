.modal__body::-webkit-scrollbar {
  width: var(--sb-size);
}

.modal__body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 10px;
}

.modal__body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 10px;
}

.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.modal__content {
  background-color: var(--white-bg);
  border-radius: var(--border-radius);
  max-width: 870px;
  padding: 30px 22px 15px 27px;
  position: absolute;
  width: 100%;
}

.modal__close {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 29px;
}

.modal__close__icon {
  fill: var(--main);
}

.modal__hidden {
  display: none;
}

.modal__body {
  max-height: 75vh;
  overflow-y: scroll;
  padding: 10px 14px 0 3px;
  width: 100%;
}
.photo__content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.imageWithFilter:not(:last-child) {
  margin-right: 15px;
}
.PhotoModal_imageWithFilter {
  cursor: pointer;
  position: relative;
  height: auto;
  margin-bottom: 15px;
}

.PhotoModal_imageWithFilter img {
  width: 368px;
  height: auto;
  margin-right: 5px;
}

.PhotoModal_video__title {
  margin-bottom: 15px;
}

.modal__content form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    margin-bottom: 20px;
  }
}
