.admin_main {
    margin-top: 120px;
    margin-bottom: 60px;
}

.admin_main_content {
    margin-top: 40px;
}

.admin_main_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.admin_main_title_back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.admin_main_content_form {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.admin_main_content_form_form {
    width: 60%;
}

.admin_main_content_form_panel_scripts {
    width: 40%;
    position: relative;
    bottom: 18px;
}

.div_save_delete_wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.admin_main_content_form_input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.admin_main_content_form_panel_scripts_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.admin_main_content_form_input input,
.admin_main_content_form_input textarea {
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    outline: none;
}

.admin_main_content_form_input textarea {
    height: 100px;
}

.admin_main_content_form_input input[type="file"] {
    border: none;
    padding: 0;
}

.image-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-preview {
    position: relative;
    display: inline-block;
}

.image-preview img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.image-preview button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.products-container_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
}

.products-container_list_item {
    background-color: var(--white-bg);
    border-radius: var(--border-radius);
    border: var(--border-table);
    color: var(--text-color);
}

.list_item_content {
    display: flex;
    gap: 20px;

}

.list_item_image {
    border-radius: 0;
    border: none;
    border-bottom: var(--border-table);

}

.list_item_image img {
    width: 60px;
    height: 50px;
    object-fit: contain;
}

.list_item_info {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.admin_main_content_form_checkbox_container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.admin_main_content_form_checkbox {
    display: inline-block;
    width: var(--checkbox-width);
    height: var(--checkbox-height);
    line-height: 20px;
    text-align: center;
    border-radius: var(--border-radius);
    border: var(--border-table);
    margin: 5px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
}

/* Убираем стандартный вид чекбокса */
.admin_main_content_form_checkbox input[type="checkbox"] {
    opacity: 0;
    position: relative;
    z-index: 100;
    width: var(--checkbox-width);
    height: var(--checkbox-height);
    border-radius: var(--border-radius);

    &:hover {
        cursor: pointer;
        background-color: #e0ffe0;
    }
}

/* Стили для выбранного чекбокса */
.admin_main_content_form_checkbox input[type="checkbox"]:checked+label {
    background-color: var(--main);
    border-radius: var(--border-radius);
    color: white;
}

/* Стили для обычной метки */
.admin_main_content_form_checkbox label {
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: var(--text-color);

    position: relative;
    bottom: 45px;
    z-index: 10;
}

.icon_back {
    cursor: pointer;
    fill: var(--main);
}

.admin_settings_container {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.admin_settings_panel {
    width: 30%;
    background-color: var(--white-bg);
    border-radius: var(--border-radius);
    border: var(--border-table);
    padding: 20px;
    height: fit-content;
    position: sticky;
    top: 120px;
}

.admin_settings_content {
    width: 70%;
    background-color: var(--white-bg);
    border-radius: var(--border-radius);
    border: var(--border-table);
    padding: 20px;
    height: fit-content;
}

.admin_settings_panel_nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.admin_settings_panel_nav_button {
    text-align: start;
    width: 100%;
    padding: 2px 0;
    color: var(--text-color);
    font-size: 14px;
}

.edit_admin_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.admin_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.admin_wrap_label,
.admin_wrap_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.admin_wrap_label label {
    font-size: 14px;
}

.admin_wrap button {
    color: #fff;
}

.edit_admin_block_content_item, .edit_admin_block_content {
    width: 100%;
}

.admin_wrap input,
.admin_wrap textarea {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    width: 100%;
    padding: 4px 20px;
    border: 1px solid #cfcfcf;
    border-radius: var(--border-radius);
    font-size: 16px;
    outline: none;
    letter-spacing: 1px;
}

.admin_wrap input[type="submit"] {
    color: var(--white);
    background-color: var(--blue);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: var(--transition);

    &:hover {
        background-color: var(--blue-light);
        transition: var(--transition);
    }
}

.admin_wrap_label_repeat div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.generate_password_button {
    width: 40% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 26px !important;

    &:hover {
        cursor: pointer;
    }
}

.generate_password_text {
    font-size: 12px;
    color: var(--damage-text-color);
}

.ql-editor {
    color: #000 !important;
    font-family: var(--font-family) !important;
}



.filter_sort_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.admin_wrap_buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    input[type="submit"] {
        color: #fff;
        border-radius: var(--border-radius);
        padding: 6px 26px;
        font-size: 12px;
    }
}