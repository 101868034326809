/* Светлая тема (по умолчанию) */

:root {
    --white-bg: #fff;
    --white-obj: #fff;
    --black-bg: #2c2c2c;
    --black-obj: #3d3d3d;
    --blocked: #f3f3f3;
    --gray: #707070;
    --gray-light: rgba(211, 211, 211, 0.55);
    --gray-dark: #4c4c4c;
    --blue: #2881b5;
    --blue-light: #459ed0;
    --green: #509750;
    --green-light: #5eb85e;
    --red: #c46e6e;
    --red-light: #d48383;
    --main: #eb5757;
    --main-light: #f07878;
    --selection: #9af49a;
    --container: 660px;
    --container-big: 1120px;
    --container-big-admin: 1380px;
    --container-greatest: 1380px;
    --transition: all linear 0.2s;
    --box-shadow: 0px 0px 4px rgba(185, 25, 25, 0.4);
    --border-radius: 3px;
    --border: 1px solid #b52828;
    --border-light: 1px solid #b6b6b6;
    --text-color: #181818;
    --sb-track-color: #cacaca00;
    --sb-thumb-color: #c5c5c5;
    --sb-size: 6px;
    --border-table: 1px solid #d1d1d1;
    --checkbox-width: 160px;
    --checkbox-height: 40px;
    --damage-text-color: #971f01;
    --font-family: 'Roboto', sans-serif;
}

[class="dark"] {
    --white-bg: #3d3d3d;
    --white-obj: #2c2c2c;
    --text-color: #fff;
    --border-table: 1px solid #494949;
    --gray-light: rgba(36, 36, 36, 0.55);
    --sb-track-color: #70707000;
    --sb-thumb-color: #707070;
    --border-light: 1px solid #919191;
    --damage-text-color: #d89162;
}