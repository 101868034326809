.successOverlay {
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: row;
}

.failedOverlay {
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: row;
}

.notificationModalSuccess {
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #fff;
  gap: 20px;
  padding: 0px 6px 0 20px;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgb(119 119 119 / 10%);
  position: relative;
  max-width: 600px;
  text-align: center;
}

.success {
  background-color: var(--green);
}

.failed {
  background-color: var(--red);
}

.notificationModalFailed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  gap: 20px;
  padding: 0px 6px 0 20px;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgb(119 119 119 / 10%);
  position: relative;
  max-width: 600px;
  text-align: center;
}

.closeButton {
  font-size: 32px;
  cursor: pointer;
  color: #fff;
}

.message {
  font-size: 16px;
  color: #333;
}
