@import "./_root.css";

.quill {
    width: 100% !important;
    margin-bottom: 20px !important;
    border-radius: var(--border-radius) !important;
    font-family: var(--font-family) !important;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
    border-radius: var(--border-radius) !important;
    background: white;
}

.ql-editor-fixed {
    min-height: 120px !important;
}

.ql-editor-fixed strong {
    font-weight: 700 !important;
}

.ql-editor-fixed em {
    font-style: italic !important;
}

.ql-align-right {
    text-align: right !important;
}

.ql-align-center {
    text-align: center !important;
}