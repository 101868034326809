.paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 60px;
}

.navButton,
.pageButton {
  padding: 6px 14px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: var(--text-color);
}

.navButton:disabled,
.pageButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pageButton.active {
  background-color: var(--main);
  color: white;
}

.pageNumbers {
  display: flex;
  gap: 5px;
}
