@import "./_root.css";

h1 {
    font-size: 24px;
    line-height: 28px;
}

h2 {
    font-size: 20px;
    line-height: 23px;
}

h1, h2, h3, h4, h5, h6, label, li, p, span {
    word-break: break-word;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

em {
    font-style: italic;
}

#script {
    padding-bottom: 50px;
    padding-top: 118px;
    position: relative;
}

select:focus,
select:active {
    outline: none;
    box-shadow: none;
}

img {
    object-fit: cover;
    width: 100%;
}

body::-webkit-scrollbar {
    width: var(--sb-size)
}

body::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 10px;

}

@supports not selector(::-webkit-scrollbar) {
    body {
        scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    }
}

.page-section {
    padding-bottom: 50px;
    padding-top: 103px;
}


.container, .container--big, .container--big--admin {
    margin: 0 auto;
    padding: 0 10px;
    position: relative;
    width: 100%;
}

.container {
    max-width: var(--container);
}

.container--big {
    max-width: var(--container-big);
}

.container--big--admin {
    max-width: var(--container-big-admin);
}

.star__red {
    color: red;
    position: relative;
    bottom: 2px;
}

.url {
    color: var(--blue) !important;
    text-decoration: underline !important;
}

.loader,
.not-found {
    margin-top: 120px;
    text-align: center;
}

.loader-2 {
    margin-top: 20px;
    text-align: center;
}

.not-found p {
    margin-top: 20px;
}