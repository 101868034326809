.script__btnBack {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.script__btnBackIcon {
  fill: var(--main);
  &:hover {
    cursor: pointer;
    fill: var(--main-light);
  }
}

.script__blocks {
  align-items: flex-start;
  display: flex;
  justify-content: center;
}
.script__body {
  max-width: 745px;
  width: 100%;
}
.script__objections {
  margin-left: 15px;
  max-width: 350px;
  width: 100%;
}

.script__objectionsTitle {
  font-weight: 400;
  background-color: var(--white-bg);
  border-radius: 3px;
  box-shadow: var(--box-shadow);
  margin-bottom: 15px;
  padding: 20px;
}

.script__accordion {
  background-color: var(--white-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 15px;
  transition: var(--transition);
  width: 100%;
}
.accordionPanel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.accordionPanelExpanded {
  max-height: 500px;
}

.accordion__cap {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 11px 20px 10px 15px;
}
.accordion__content {
  font-family: var(--R_Light), sans-serif;
  padding: 5px 72px 15px 15px;
  position: relative;
  line-height: normal;
  font-weight: 300;
}
.accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.3s ease-out;
}

.accordion__content--open {
  max-height: 1000px;
}
.accordion__panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordion__panel--expanded {
  max-height: 500px; /* Задайте значение, которое подходит под содержимое */
}
.accordionPanel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordionPanelExpanded {
  max-height: 500px; // Настройте значение под размер содержимого
}

.accordionPanel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordionPanelExpanded {
  max-height: 500px; // Настройте значение под размер содержимого
}

.script__block {
  align-items: center;
  background-color: var(--white-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  max-width: 745px;
  position: relative;
  width: 100%;
}
.script__blockPhoto {
  cursor: pointer;
  height: 208px;
  max-width: 180px;
  width: 100%;
}
.script__blockPhoto img {
  border: var(--border);
  border-radius: var(--border-radius);
  height: inherit;
}
.script__blockInfo {
  padding: 20px;
  width: 100%;
}
.script__blockInfoCount {
  bottom: 15px;
  color: var(--blue);
  left: 15px;
  position: absolute;
}
.script__blockInfoList {
  margin-bottom: 41px;
}
.script__blockInfoListItem:not(:last-child) {
  margin-bottom: 15px;
}
.script__blockFunctions {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.script__blockFunctions .script__blockFunction:not(:last-child) {
  margin-right: 20px;
}
.script__blockFunctionBtn {
  background-color: var(--main);
  border-radius: var(--border-radius);
  color: #fff;
  height: 40px;
  padding: 0 12px;
  text-align: center;
  transition: var(--transition);
  width: 100%;

  &:hover {
    background-color: var(--main-light);
  }
}
