.projects {
  position: relative;
}

.projects__title {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 23px;
}

.item {
  align-items: center;
  background-color: var(--white-bg);
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 640px;
  padding: 10px 12px 11px;
  transition: var(--transition);
  width: 100%;
  &:hover {
    background-color: var(--gray-light);
  }
}

.main {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.arrow {
  position: relative;
  top: 3px;
  width: 14px;
  transform: rotate(180deg);
  fill: var(--main);
}

.scripts__backButton {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  background-color: var(--white-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: var(--text-color);
  font-size: 24px;
  height: 56px;
  line-height: 28px;
  margin-bottom: 15px;
  max-width: 640px;
  position: relative;
  text-align: center;
  width: 100%;
  &:hover {
    background-color: var(--gray-light);
  }
}

.scripts__arrow {
  position: relative;
  fill: var(--main);
}

.scripts__regulationsButton {
  margin-bottom: 15px;
  background-color: var(--main);
  border-radius: var(--border-radius);
  color: #fff;
  height: 40px;
  padding: 0 12px;
  text-align: center;
  transition: var(--transition);
  width: 100%;
  &:hover {
    background-color: var(--main-light);
  }
}

.scripts__item {
  align-items: center;
  background-color: var(--white-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto 15px;
  max-width: 640px;
  position: relative;
  transition: var(--transition);
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}
.scripts__item .scripts__wrapper {
  height: 136px;
  max-width: 180px;
  width: 100%;
}
.scripts__item img {
  border: var(--border);
  border-radius: var(--border-radius);
  height: inherit;
}
.scripts__item .scripts__info {
  padding: 20px 20px 10px;
  width: 100%;
}

.scripts__item .scripts__title {
  margin-bottom: 10px;
  padding-right: 105px;
  text-transform: uppercase;
}

.scripts__item .scripts__listItem:not(:last-child) {
  margin-bottom: 10px;
}

.scripts__item:hover {
  transform: scale(1.01);
}

.education__item {
  align-items: center;
  background-color: var(--white-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  max-width: 640px;
  min-height: 136px;
  position: relative;
  transition: var(--transition);
  width: 100%;
  &:hover {
    transform: scale(1.01);
  }
}

.education__item .img__wrapper {
  height: 136px;
  max-width: 180px;
  position: relative;
  width: 100%;
}

.education__info {
  padding: 20px;
  width: 100%;
  font-weight: 300;

  strong {
    font-weight: 500;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }
  ol {
    list-style: decimal;
    padding-left: 20px;
  }
}

.education__video {
  border: var(--border);
  border-radius: var(--border-radius);
  height: 100%;
  width: 100%;
}

.education__title {
  margin-bottom: 20px;
  padding-right: 60px;
  font-size: 16px;
}

.education__description {
  display: -webkit-box;
  -webkit-line-clamp: 3; // Количество строк до сокращения
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalEducationItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalImgWrapper {
  height: 136px;
  max-width: 180px;
  position: relative;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.modalEducationInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modalEducationTitle,
.modalEducationDescription {
  background-color: var(--white-obj);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 11px 12px 10px;

  font-weight: 300;

  strong {
    font-weight: 500;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }
  ol {
    list-style: decimal;
    padding-left: 20px;
  }
}

.fullScreenVideo {
  height: 540px;
  width: 100%;
}
