.login {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.login__inner {
  background-color: #fff;
  border-radius: 3px;
  border-radius: var(--border-radius);
  box-shadow: 0 0 4px rgba(40, 130, 181, 0.4);
  box-shadow: var(--box-shadow);
  margin: 0 auto;
  max-width: 437px;
  padding: 50px 76px;
  text-align: center;
  width: 100%;
}

.LoginForm_field_h3 {
  margin-bottom: 30px;
  color: var(--text-color);
}

.login__logo {
  width: 120px;
  margin-bottom: 30px;
}

.LoginForm_field_password {
  margin: 15px 0 25px;
}

.Input_input {
  background-color: #fbfbfb;
  color: #707070;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 11px 12px 10px;
  text-align: left;
  font-size: 16px;
  width: 100%;
}

.Button_button {
  background-color: var(--main);
  border-radius: var(--border-radius);
  color: #fff;
  height: 40px;
  padding: 0 12px;
  text-align: center;
  transition: var(--transition);
  width: 100%;
  font-size: 16px;
}

.Button_button:hover {
  background-color: var(--main-light);
}
