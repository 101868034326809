@import "./_root.css";

.sort_container {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
    gap: 40px;
}

.sort_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.sort_item p {
    font-size: 12px;
    font-weight: 500;
}

.sort_item select {
    font-family: "Roboto";
    font-size: 12px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--white-obj);
    color: var(--text-color);

    &:hover {
        cursor: pointer;
    }
}