table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
}

th {
    border-top: var(--border-table);
    border-bottom: var(--border-table);
    padding: 6px;
    text-align: left;
    font-weight: 500;
}

td {
    border-top: var(--border-table);
    border-bottom: var(--border-table);
    padding: 6px;
    text-align: left;
    font-weight: 400;
}

th {
    background-color: var(--white-bg);
}

.actions {
    text-align: center;
}

.th_edit,
.th_delete,
.th_duplicate {
    width: 8%;
}

button {
    padding: 5px 10px;
    margin: 2px;
    border: none;
    cursor: pointer;
}