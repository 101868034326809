.header {
  display: flex;
  align-items: center;
  background-color: var(--white-bg);
  box-shadow: var(--box-shadow);
  height: 88px;
  left: 0;
  padding: 7px 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.container {
  margin: 0 auto;
  max-width: 1460px;
  padding: 0 30px 0 10px;
  width: 100%;
}

.logo {
  height: 54px;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.middle {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.Input_input {
  background-color: #fbfbfb;
  color: #707070;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 11px 12px 10px;
  text-align: left;
  font-size: 16px;
  width: 320px;
}

.navigation {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 40px;
  gap: 40px;
}

button {
  font-size: 16px;
  color: var(--main);
  background: none;
}

.right {
  align-items: center;
  display: flex;
  justify-content: center;
}

.user {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 34px;
  margin-left: 40px;
  margin-right: 40px;
  gap: 10px;
}

.user__info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.user__img {
  height: 34px;
}

.user__name {
  font-size: 14px;
  color: var(--main);
}

.user__logout {
  color: var(--text-color);
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
}
