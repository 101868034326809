.panel {
    margin-bottom: 15px;
    background-color: var(--white-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-bottom: 15px;
    transition: var(--transition);
    width: 100%;

    &:hover {
        transform: scale(1.01);
    }
}

.panel__title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 11px 20px 10px 15px;

    &:hover {
        cursor: pointer;
        background-color: var(--white-bg);

    }
}



.panel__icon {
    stroke: var(--main);
}

.panel__label {
    width: 90%;
    text-align: left;
    padding: 0;
    align-items: center;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
}

.panel__inner {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
}

.panel__content {
    font-weight: 300;
    padding: 5px 72px 15px 15px;
    font-size: 14px;
}

.panel__content h1 {
    font-size: 2em;
}

.panel__content h2 {
    font-size: 1.5em;
}

.panel__content h3 {
    font-size: 1.17em;
}

.panel__content strong {
    font-weight: 600;
}

.panel__content ol {
    list-style: decimal !important;
}

.panel__content ul {
    list-style: disc !important;
}

.panel__content ol li {
    margin-left: 15px;
}

.panel__content ul li {
    margin-left: 15px;
}