.delete,
.edit,
.edit_pass,
.edit_reglament,
.duplicate {
    border-radius: var(--border-radius);
    color: white;
    transition: var(--transition);
    padding: 6px 26px;
    line-height: normal;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 16px !important;
        width: auto;
    }
}

.delete {
    background-color: var(--red);

    &:hover {
        cursor: pointer;
        background-color: var(--red-light);
        transition: var(--transition);
    }
}

.edit {
    background-color: var(--green);

    &:hover {
        background-color: var(--green-light);
        transition: var(--transition);
    }
}

.edit_pass {
    background-color: var(--blue);

    &:hover {
        background-color: var(--blue-light);
        transition: var(--transition);
    }
}

.duplicate {
    background-color: var(--blue);

    &:hover {
        background-color: var(--blue-light);
        transition: var(--transition);
    }
}

.edit_reglament {
    background-color: var(--blue);

    &:hover {
        background-color: var(--blue-light);
        transition: var(--transition);
    }
}

.admin_main_title_button {
    background-color: var(--blue);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px !important;
    transition: var(--transition);


    &:hover {
        background-color: var(--blue-light);
        transition: var(--transition);
    }
}


.action_button {
    width: fit-content;
    background-color: var(--blue);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: var(--blue-light);
    }
}