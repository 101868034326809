@import "./assets/root/_root.css";
@import "./assets/root/_font.css";
@import "./assets/root/_reset.css";
@import "./assets/root/_global.css";
@import "./assets/root/_admin.css";
@import "./assets/root/_table.css";
@import "./assets/root/_buttons.css";
@import "./assets/root/_ql_editor.css";
@import "./assets/root/_sort.css";
@import "./assets/root/_photoswipe.css";

body {
    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.5;
    color: var(--text-color);
    background-color: var(--white-obj);
    letter-spacing: 1px;
}

textarea {
    font-family: "Roboto";
    font-size: 16px;
}